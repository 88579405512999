import React, { useState } from 'react';
import 'whatwg-fetch';

const ContactPage = () => {
    const [emailValue, setEmailValue] = useState('');
    const [subjectValue, setSubjectValue] = useState('');
    const [bodyValue, setBodyValue] = useState('');

    const handleChange1 = (event) => {
        setEmailValue(event.target.value);
    };

    const handleChange2 = (event) => {
        setSubjectValue(event.target.value);
    };

    const handleChange3 = (event) => {
        setBodyValue(event.target.value);
    };

    const handleButtonClick = () => {
        const reqData = {
            email: emailValue,
            subject: subjectValue,
            body: bodyValue
        }
        console.log(JSON.stringify(reqData))
        sendMessage(reqData)
    }

    async function sendMessage(data = {}) {
        const response = await fetch("http://127.0.0.1:8080/api/users/iwcontact", {
            method: "POST",
            mode: "no-cors",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(data),
        });

    }

    return (
        <div>
            <h1>Contact Us</h1>
            <p>Please fill out your email and what you wish to contact us about</p>
            <h4>Email</h4>
            <input 
                className="email-box"
                type="text"
                value={emailValue}
                onChange={handleChange1}
            />
            <h4>Subject</h4>
            <input 
                className="subject-box"
                type="text"
                value={subjectValue}
                onChange={handleChange2}
            />
            <h4>Message</h4>
            <input 
                className="body-box"
                type="text"
                value={bodyValue}
                onChange={handleChange3}
            />
            <h4><button onClick={handleButtonClick}>Send</button></h4>

        </div>
    )
}

export default ContactPage;