import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { authContext } from '../App';

const ApiPage = () => {
    const { auth } = useContext(authContext);
    const [ NonAuthList, setNonAuthList] = useState([]);
    const [ DocCheck, setDocCheck ] = useState('');

    const handleButtonClick = () => {
        if (auth !== "0") {
            fetchDoc();
            console.log("Doc Check: " + DocCheck)
            console.log(NonAuthList)
            setDocCheck('1');
        }
    }

    const fetchDoc = async () => {
        try {
            const response = await axios.post('/api/users/apidoc', {
                auth: auth
            })
            const { data } = response;
            const { item: [nac, ac] } = data;
            setNonAuthList(nac.item)
            setDocCheck('21')
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div>
            <h1>Api</h1>
            <p>Documentation for IronwoodAPI</p>
            <p>AuthToken: {auth}</p>
            <h4><button onClick={handleButtonClick}>Get Api Doc</button></h4>
            <ul>
                {NonAuthList.map((item, index) => (
                    <li key={index}>
                        <p>Request Name: {item.name}</p>
                        <p>Request Method: {item.request.method}</p>
                        <p>Request Url: {item.request.url}</p>
                    </li>

                ))}
            </ul>
        </div>
    )
}

export default ApiPage;