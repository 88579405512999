import React from 'react';
import { StyledDrawer } from './Drawer.styled';
import { Link } from 'react-router-dom'

export const Drawer = ({ open }) => {
  return (
    <StyledDrawer open={open}>
      <Link to="/">Home</Link>
      <Link to="/about">About</Link>
      <Link to="/contact-us">Contact Us</Link>
      <Link to="/api">API Docs</Link>
      <Link to="login">Login</Link>
    </StyledDrawer>
  );
};