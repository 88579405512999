import React from 'react';


class AboutPage extends React.Component {
    render() {
        return (
            <div>
                <h1>About</h1>
                <p>IronwoodAPI seeks to build a start to finish interface for you and your customers</p>
            </div>
        )
    }
}

export default AboutPage;