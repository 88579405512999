import React, { useState , useContext } from 'react';
import axios from 'axios';
import { authContext } from '../App';

const LoginPage = () => {
    const { auth, updateAuth } = useContext(authContext);
    const [userValue, setUserValue] = useState('');
    const [passValue, setPassValue] = useState('');

    const handleChange1 = (event) => {
        setUserValue(event.target.value);
    }

    const handleChange2 = (event) => {
        setPassValue(event.target.value);
    }

    const handleButtonClick = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post('/api/users/weblogin', {
                user: userValue,
                pass: passValue
            });

            console.log(response.data);
            updateAuth(response.data.auth);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            <h1>Login</h1>
            <h4>Username</h4>
            <input 
                className="user-box"
                type="text"
                value={userValue}
                onChange={handleChange1}
            />
            <h4>Password</h4>
            <input 
                className="pass-box"
                type="text"
                value={passValue}
                onChange={handleChange2}
            />
            <h4><button onClick={handleButtonClick}>Send</button></h4>
            <h2>AuthToken: {auth}</h2>
        </div>
    )
}

export default LoginPage;