import React from 'react';
import './HomePage.css';

class HomePage extends React.Component {
    render() {
        return (
            <div>
                <img src={require('./FullLogo.png')} alt="Logo" className="Logo" />
                <h1>Welcome!</h1>
            </div>
        )
    }
}

export default HomePage;