import React, { useState } from 'react'
import { BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom'

// pages
import HomePage from './pages/HomePage'
import AboutPage from './pages/AboutPage'
import ContactPage from './pages/ContactPage'
import ApiPage from './pages/ApiPage'
import LoginPage from './pages/LoginPage'

import MiniLogo from './TransparentLogo.png'
import Title from './TitleLogo.png'

import { Menu } from './components'
import { Email, Person } from '@mui/icons-material'

const items = [
  { label: 'Home', url: '#' },
  { label: 'About', url: '#' },
  { label: 'Contact', url: '#' },
]

export const authContext = React.createContext({
  auth: "0",
  setAuth: () => {}
})

export default function App() {
  const [auth, setAuth] = useState("0");

  const updateAuth = (newAuth) => {
    setAuth(newAuth)
  }

  return (
    <authContext.Provider value={{auth, updateAuth}}>
      <Router className="App">
        <div className="util-bar"></div>
        <Link to="/">
          <img src={MiniLogo} alt="MiniLogo" className="mini-logo" />
        </Link>
        <Link to="login">
          <Person className="login-icon" fontSize="large"></Person>
        </Link>
        <Link to="contact-us">
          <Email className="email-icon" fontSize="large"></Email>
        </Link>
        <img src={Title} className="title-logo" />

        <Menu items={items} />
        <main>
          <Routes>
            <Route className="home-button" index element={<HomePage />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="api" element={<ApiPage />} />
            <Route path="contact-us" element={<ContactPage />} />
            <Route path="login" element={<LoginPage />} />
          </Routes>
        </main>
      </Router>
    </authContext.Provider>
  )
}


