import React, { useState, useRef } from 'react';
import { Burger } from './Burger';
import { Drawer } from './Drawer';
import { useOnClickOutside } from './hooks';


export const Menu = ({ items = [] }) => {
    const [open, setOpen] = useState(false);

    const node = useRef();

    useOnClickOutside(node, () => {
        setOpen(false);
    });

    return (
        <div ref={node}>
            <Burger open={open} setOpen={setOpen} />
            <Drawer open={open} setOpen={setOpen} items={items} />
        </div>
    )
}